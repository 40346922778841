import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';

import { schema } from './validations';
import { getUserData } from '~/services/user';
import TrilhaApi from './services';
import { TelasProps } from './types';

type TrilhaContextData = {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  getTela: () => void;
  optionsTelas: TelasProps[];
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
};

export const TrilhaContext = createContext({} as TrilhaContextData);

interface TrilhaContextProviderProps {
  children: ReactNode;
}

export function TrilhaContextProvider({
  children,
}: TrilhaContextProviderProps): JSX.Element {
  const user = getUserData();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
    unregister,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [optionsTelas, setOptionsTelas] = useState<TelasProps[]>([]);

  async function getTela() {
    try {
      const { data } = await TrilhaApi.getTelas(user?.cod_controle);
      if (data.length > 0) {
        setOptionsTelas(data);
      }
    } catch (e: any) {
      console.log(e);
    }
  }

  return (
    <TrilhaContext.Provider
      value={{
        register,
        control,
        errors,
        setValue,
        watch,
        getTela,
        reset,
        handleSubmit,
        getValues,
        optionsTelas,
        setError,
        clearErrors,
        unregister,
      }}
    >
      {children}
    </TrilhaContext.Provider>
  );
}

export const useTrilha = (): TrilhaContextData => {
  return useContext(TrilhaContext);
};
